$primary: #ee2c36;

$link-color: #fff;
$navbar-nav-link-padding-x: 1rem;
$headings-color: #fff;


// pagination
$pagination-color: #3A071C;
$pagination-active-bg: #3A071C;


$border-radius: 0.35rem;
// Extra small button sizing
$btn-padding-y-xs: 0.25rem;
$btn-padding-x-xs: 0.5rem;
$btn-font-size-xs: 0.7rem;
$btn-border-radius-xs: $border-radius;

// Extra large button sizing
$btn-padding-y-xl: 1.25rem;
$btn-padding-x-xl: 1.5rem;
$btn-font-size-xl: 1.125rem;


@import 'bootstrap/scss/bootstrap.scss';
@import 'tom-select/dist/css/tom-select.bootstrap5.css';


.btn-icon {
  padding: 0;

  justify-content: center;
  overflow: hidden;

  border-radius: 100%;

  flex-shrink: 0;

  height: calc((#{$btn-font-size} * #{$btn-line-height}) + (#{$btn-padding-y} * 2) + (#{$btn-border-width * 2})) !important;
  width: calc((#{$btn-font-size} * #{$btn-line-height}) + (#{$btn-padding-y} * 2) + (#{$btn-border-width * 2})) !important;

  .feather {
    margin-top: 0 !important;
  }

  &.btn-xl {
    height: calc((#{$btn-font-size-xl} * #{$btn-line-height}) + (#{$btn-padding-y-xl} * 2) + (#{$btn-border-width * 2})) !important;
    width: calc((#{$btn-font-size-xl} * #{$btn-line-height}) + (#{$btn-padding-y-xl} * 2) + (#{$btn-border-width * 2})) !important;
    border-radius: 100%;
  }

  &.btn-lg {
    height: calc((#{$btn-font-size-lg} * #{$btn-line-height}) + (#{$btn-padding-y-lg} * 2) + (#{$btn-border-width * 2})) !important;
    width: calc((#{$btn-font-size-lg} * #{$btn-line-height}) + (#{$btn-padding-y-lg} * 2) + (#{$btn-border-width * 2})) !important;
  }

  &.btn-sm {
    height: calc((#{$btn-font-size-sm} * #{$btn-line-height}) + (#{$btn-padding-y-sm} * 2) + (#{$btn-border-width * 2})) !important;
    width: calc((#{$btn-font-size-sm} * #{$btn-line-height}) + (#{$btn-padding-y-sm} * 2) + (#{$btn-border-width * 2})) !important;
  }

  &.btn-xs {
    height: calc((#{$btn-font-size-xs} * #{$btn-line-height}) + (#{$btn-padding-y-xs} * 2) + (#{$btn-border-width * 2})) !important;
    width: calc((#{$btn-font-size-xs} * #{$btn-line-height}) + (#{$btn-padding-y-xs} * 2) + (#{$btn-border-width * 2})) !important;
    border-radius: 100%;
  }

  &.btn-link {
    text-decoration: none;
  }

}

textarea {
  resize: none;
}

.pagination {
  justify-content: center !important;
}

.card-image {
  max-width: 600px;
}

.card-image img {
  border: 1px solid #000;
}


/* Colorize them */
.red-bauble
{
  color: #c8171f;
}

.blue-bauble
{
  color: #00a1ee; /* Fallback */
}

.yellow-bauble
{
  color: #fcb83d; /* Fallback */
}

.green-bauble
{
  color: #4d8d00; /* Fallback */
}



/**
 * Ornaments
 */
.ornaments {
  margin-top: 60px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  //left: 50%;
  //position: absolute;
  //top: 50%;
  //transform: translate( -50%, -50% );
}

.ornament {
  background-color: #f06;
  background-image: radial-gradient(circle at right bottom,rgba(0,0,0,.6),rgba(0,0,0,.0));
  border-radius: 50%;
  box-shadow: inset 5px 5px 1em rgba(255,255,255,.6);
  height: 44px;
  margin: 0 1em;
  margin-top: 40px;
  opacity: 0.5;
  position: relative;
  width: 44px;



  &:nth-child(8n-7) {
    animation: ornament 2s 0s infinite;
  }
  &:nth-child(8n-5) {
    transform: rotate( -5deg );
    animation: ornament 2s 0.5s infinite;
  }

  &:nth-child(8n-3) {
    transform: rotate( 15deg );
    animation: ornament 2s 1s infinite;
  }
  &:nth-child(8n-1) {
    transform: rotate( -10deg );
    animation: ornament 2s 1.5s infinite;
  }
  //
  //&:nth-child(8n-1) {
  //  transform: rotate( 10deg );
  //  animation: ornament 2s 1.5s infinite;
  //}

  &::before {
    position: absolute;
    content: "";
    display: block;
    width: 10px;
    height: 5px;
    top: -4px;
    background-color: #fff;
    left: 50%;
    margin-left: -5px;
    border-radius: 2px 2px 0 0;
    background-image: linear-gradient( to right, rgba(0, 0, 0, 0), rgba( 0, 0, 0, 0.2 ), rgba( 0, 0, 0, 0 ) );
  }

  .hook {
    position: absolute;
    border-color: #fff;
    height: 20px;
    width: 10px;
    border-width: 1px 1px 0 1px;
    top: -24px;
    border-style: solid;
    border-radius: 50% 50% 0 0;
    left: 21px;

    &::before {
      content: "";
      display: block;
      height: 50%;
      background-color: #00aeef;
      position: absolute;
      bottom: 0;
      width: 3px;
      right: -2px;
    }
  }
}

.b-yellow {
  background-color: #fcb83d;
}

.b-red {
  background-color: #c8171f;
}

.b-blue {
  background-color: #00a1ee;
}

.b-green {
  background-color: #4d8d00;
}

.b-black {
  background-color: #000;
}

.bauble-num {
  padding-top: 10px;
  display: block;
  color: #fff;
}

@keyframes ornament {
  0% { opacity: 0.5; }
  33% { opacity: 1; }
  67% { opacity: 0.5; }
}
